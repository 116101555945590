import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from './config';

const ProductLiveSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        let allProducts = [];
        let page = 1;
        let totalPages = 1;

        while (page <= totalPages) {
          const response = await axios.get(
            `${config.baseUrl}/products`,
            {
              params: {
                page,
                per_page: 100, 
                _embed: true,
              },
              auth: {
                username: config.consumerKey,
                password: config.consumerSecret,
              },
            }
          );

          if (response.headers['x-wp-totalpages']) {
            totalPages = parseInt(response.headers['x-wp-totalpages']);
          }

          allProducts = [...allProducts, ...response.data];
          page++;
        }

        setAllProducts(allProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchAllProducts();
  }, []);

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term === '') {
      setSearchResults([]);
      return;
    }

    const filteredProducts = allProducts.filter(product =>
      product.name.toLowerCase().includes(term) ||
      product.sku.toLowerCase().includes(term) ||
      String(product.id).includes(term)
    );
    setSearchResults(filteredProducts);
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by Name, SKU, or ID..."
        className="w-full p-2 mb-4 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {searchResults.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">Image</th>
              <th className="px-4 py-2 border-b">Name</th>
              <th className="px-4 py-2 border-b">SKU</th>
              <th className="px-4 py-2 border-b">ID</th>
              <th className="px-4 py-2 border-b">Price</th>
              <th className="px-4 py-2 border-b">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map(product => (
              <tr key={product.id} className="border-b">
                <td className="px-4 py-2">
                  {product.image ? (
                    <img src={product.image} alt={product.name} className="w-16 h-16 object-cover rounded-md" />
                  ) : (
                    <span className="text-gray-500">No Image</span>
                  )}
                </td>
                <td className="px-4 py-2">{product.name}</td>
                <td className="px-4 py-2">{product.sku}</td>
                <td className="px-4 py-2">{product.id}</td>
                <td className="px-4 py-2">${product.price}</td>
                <td className="px-4 py-2">{product.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        searchTerm && (
          <div className="text-center text-gray-500">
            No products found.
          </div>
        )
      )}
    </div>
  );
};

export default ProductLiveSearch;
